import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axiosConfig";
import {
  transformData,
  triggerCSVDownload,
  scrollToElement,
} from "../../helpers/functions";

const initialState = {
  uuid: "",
  searchText: "",
  sourceName: "",
  searchResults: [],
  searchResultsForView: [],
  coordinates: [],
  minMaxCoordinates: { freq: [null, null], flux: [null, null] },
  showSourceInfo: false,
  sourceInfo: {},
  raDecValue: {},
  valueDB: "SSDC",
  isLoading: false,
  isSearchTextLoading: false,
  isLoadingRelatedArticles: false,
  showSections: false,
  error: null,
  optionsError: null,
  groupedLegends: {},
  forceRun: false,
  showPlotLoader: false,
  plotDataErrorText: "",
  relatedArticles: [],
  relatedArticlesError: null,
  searchResultsIndex: null,
  axisViews: {
    xAxis: {
      value: "hz",
      name: ["hz", "freq_ev"],
      label: ["Hz", "eV"],
    },
    yAxis: {
      value: "erg",
      name: [
        "erg",
        "flux_ev",
        "flux_norm",
        "flux_jyhz",
        "flux_wm2",
        "nufnu_fnu_jy",
      ],
      label: [
        "𝜈𝐹(𝜈) [erg cm<sup>-2</sup> s<sup>-1</sup>]",
        "𝜈𝐹(𝜈) [TeV cm<sup>-2</sup> s<sup>-1</sup>]",
        "dN/dE [eV<sup>-1</sup> cm<sup>-2</sup> s<sup>-1</sup>]",
        "𝜈𝐹(𝜈) [Jy × Hz]",
        "𝜈𝐹(𝜈) [w/m<sup>2</sup>]",
        "𝐹(𝜈) [Jy]",
      ],
    },
  },
  mjdRange: [51000, 61500],
  filterDates: {
    start: null,
    end: null,
    startInNum: null,
    endInNum: null,
  },
  excludedOptions: "",
  excludedRanges: {
    catalogs: null,
    freqFlux: null,
  },
  dataInKnown: [],
  dataGammaRay: [],
  logs: "",
  videoUrl: "",
  isLoadingVideo: false,
  errorVideo: null,
  aladinText: "",
};

let currentAbortController = null;

// Async thunk
export const fetchSearchResults = createAsyncThunk(
  "dataAccess/fetchSearchResults",
  async (source_name_chars, { rejectWithValue, signal }) => {
    if (currentAbortController) {
      currentAbortController.abort();
    }

    currentAbortController = new AbortController();

    try {
      const response = await axios.post(
        "/name_fill/",
        { source_name_chars },
        {
          headers: { "Content-Type": "application/json" },
          signal: currentAbortController.signal,
        }
      );

      let results = [];

      // Check if response.data.results exists and is an array
      if (response.data.results) {
        results = response.data.results.map((result) => {
          let newResult = { ...result }; // Create a new object, instead of modifying the original one

          // Check if the keys exist and are string numbers
          if (newResult.valueDEC) {
            // Fix the string number to 4 decimal places
            newResult.valueDEC = parseFloat(newResult.valueDEC).toFixed(4);
          }

          if (newResult?.valueRA) {
            // Fix the string number to 4 decimal places
            newResult.valueRA = parseFloat(newResult.valueRA).toFixed(4);
          }

          return newResult;
        });
      }

      return {
        searchResults: response.data.results,
        searchResultsForView: results,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    } finally {
      if (currentAbortController.signal === signal) {
        currentAbortController = null;
      }
    }
  }
);

export const fetchSourceInfo = createAsyncThunk(
  "dataAccess/fetchSourceInfo",
  async ({ uuid }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/source_info/${uuid}`, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.data) {
        let newData = {
          ...response.data,
          ra: parseFloat(response.data.ra).toFixed(4),
          dec: parseFloat(response.data.dec).toFixed(4),
          gal_lat: parseFloat(response.data.gal_lat).toFixed(4),
          gal_long: parseFloat(response.data.gal_long).toFixed(4),
        };

        return newData;
      }
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchThreadInfo = createAsyncThunk(
  "dataAccess/fetchThreadInfo",
  async ({ data, forceRun = false }, { rejectWithValue }) => {
    const force = `${forceRun ? "?force=true" : ""}`;

    try {
      const response = await axios.post(`/vou_json/${force}`, data, {
        headers: { "Content-Type": "application/json" },
      });
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCheckStatusOfData = createAsyncThunk(
  "dataAccess/fetchCheckStatusOfData",
  async ({ uuid }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/vou_json/${uuid}`);

      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCoordinates = createAsyncThunk(
  "dataAccess/fetchCoordinates",
  async ({ uuid }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/freq_flux_data/${uuid}`);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchArticlesAPI = createAsyncThunk(
  "dataAccess/fetchArticlesAPI",
  async (sourceName, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/articles/?search_type=abstract&query_text=${encodeURIComponent(
          sourceName
        )}`
      );
      if (response.data?.response?.docs) {
        return response.data.response.docs;
      }
      throw new Error("No articles found");
    } catch (error) {
      console.error("Error fetching data: ", error);
      return rejectWithValue(error);
    }
  }
);

const handleErrors = (dispatch, error, uuid, intervalId) => {
  dispatch(setUuid(""));
  dispatch(setPlotDataErrorText("Error"));
  dispatch(setShowSections(false));
  dispatch(setShowPlotLoader(false));
  clearInterval(intervalId);
  throw error;
};

let intervalId = null;

const fetchStatusAndCoordinates = (dispatch, uuid) => {
  dispatch(setLogs(""));
  return new Promise(async (resolve, reject) => {
    intervalId = setInterval(async () => {
      const checkStatusResponse = await dispatch(
        fetchCheckStatusOfData({ uuid })
      );

      if (checkStatusResponse.error) {
        handleErrors(dispatch, checkStatusResponse.error, uuid, intervalId);
        clearInterval(intervalId);
        reject(new Error("Status check error"));
      }

      const status = checkStatusResponse.payload.status;

      const logs = checkStatusResponse.payload.logs;
      dispatch(setLogs(logs));

      if (status === "processing") {
        dispatch(setShowPlotLoader(true));
      } else {
        clearInterval(intervalId); // Clear the interval once processing is not the status

        if (status === "done") {
          const coordinatesResponse = await dispatch(
            fetchCoordinates({ uuid })
          );
          if (coordinatesResponse.error) {
            handleErrors(dispatch, coordinatesResponse.error, uuid, intervalId);
            reject(new Error("Error fetching coordinates"));
          } else {
            resolve(); // Resolve the promise when status is "done" and coordinates are fetched
          }
        } else if (status === "no_data") {
          dispatch(setShowPlotLoader(false));
          dispatch(setPlotDataErrorText("No SED Data Available"));
          reject(new Error("No SED Data Available"));
        } else if (status === "error") {
          dispatch(setShowPlotLoader(false));
          dispatch(
            setPlotDataErrorText("Something went wrong! Please try later!")
          );
          reject(new Error("Status check returned an error"));
        }
      }
    }, 2000); // This interval will check the status every 10 seconds
  });
};

export const fetchSedLcVideo = createAsyncThunk(
  "dataAccess/fetchSedLcVideo",
  async ({ ra, dec }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/sed_lc?ra=${ra}&dec=${dec}`, {
        responseType: "blob",
      });

      const videoUrl = URL.createObjectURL(response.data);
      return videoUrl;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.status);
    }
  }
);

export const handleShowSections = createAsyncThunk(
  "dataAccess/handleShowSections",
  async (selectedData = null, { dispatch, getState, rejectWithValue }) => {
    const { forceRun, searchText } = getState().dataAccess;

    dispatch(setLoading(true));
    dispatch(setError(null));
    dispatch(setCoordinates([]));
    dispatch(setGroupedLegends({}));
    dispatch(setRelatedArticles([]));
    dispatch(setRelatedArticlesError(null));
    dispatch(setPlotDataErrorText(""));
    dispatch(setShowSourceInfo(true));
    dispatch(setShowSections(true));
    dispatch(setShowPlotLoader(true));

    await scrollToElement("scrollToDataWithInputData");

    try {
      let threadInfoResponse = null;

      threadInfoResponse = await dispatch(
        fetchThreadInfo({
          data: {
            source_name: selectedData.sourceName,
            database_name: selectedData.valueDB,
            ra: selectedData.ra + "",
            dec: selectedData.dec + "",
          },
          forceRun,
        })
      );

      if (threadInfoResponse.error) {
        throw threadInfoResponse.error;
      }

      const uuid = threadInfoResponse.payload.uuid;

      dispatch(setUuid(uuid));

      fetchStatusAndCoordinates(dispatch, uuid)
        .then(() => {
          return dispatch(fetchSourceInfo({ uuid }));
        })
        .then((sourceInfo) => {
          dispatch(setSourceInfo(sourceInfo.payload));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          console.error("Error after fetchStatusAndCoordinates:", error);
        });

      try {
        dispatch(
          fetchSedLcVideo({ ra: selectedData.ra, dec: selectedData.dec })
        );
      } catch (error) {
        console.error("Error fetching sed_lc animation");
      }

      try {
        const relatedArticles = await dispatch(
          fetchArticlesAPI(selectedData?.sourceName)
        );

        dispatch(setRelatedArticles(relatedArticles.payload));
      } catch (error) {
        dispatch(setLoading(false));
        console.error("Error fetching related articles:", error);
        dispatch(setRelatedArticlesError(error));
      }

      dispatch(setAladinText(searchText));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setError("Error fetching search results"));
      clearInterval(intervalId);
      return rejectWithValue(error.message);
    }
  }
);

export const filterFetchCoordinates = createAsyncThunk(
  "dataAccess/filterFetchCoordinates",
  async (
    { resetGroupedLegends = false },
    { rejectWithValue, getState, dispatch }
  ) => {
    const { uuid, axisViews, mjdRange, filterDates, excludedRanges } =
      getState().dataAccess;

    let newExcludedOptions = "";

    if (excludedRanges.catalogs?.length > 0 && !resetGroupedLegends) {
      newExcludedOptions += excludedRanges.catalogs
        .map((catalog) => `exclude_catalogs=${catalog}`)
        .join("&");
    }

    if (excludedRanges.freqFlux?.length > 0 && !resetGroupedLegends) {
      if (newExcludedOptions !== "") newExcludedOptions += "&";
      newExcludedOptions += excludedRanges.freqFlux
        .map((freqFluxRange) => `exclude_freq_ranges=${freqFluxRange}`)
        .join("&");
    }

    if (mjdRange[0] !== null && mjdRange[0] !== 0) {
      if (newExcludedOptions !== "") newExcludedOptions += "&";
      newExcludedOptions += `mjd_start=${mjdRange[0]}`;
    }

    if (mjdRange[1] != null && mjdRange[1] !== 0) {
      if (newExcludedOptions !== "") newExcludedOptions += "&";
      newExcludedOptions += `mjd_end=${mjdRange[1]}`;
    }

    if (filterDates.startInNum != null && filterDates.startInNum !== 0) {
      if (newExcludedOptions !== "") newExcludedOptions += "&";
      newExcludedOptions += `mjd_start=${filterDates.startInNum}`;
    }

    if (filterDates.endInNum != null && filterDates.endInNum !== 0) {
      if (newExcludedOptions !== "") newExcludedOptions += "&";
      newExcludedOptions += `mjd_end=${filterDates.endInNum}`;
    }

    if (axisViews.xAxis.value !== "hz") {
      if (newExcludedOptions !== "") newExcludedOptions += "&";
      newExcludedOptions += `x_axis=${axisViews.xAxis.value}`;
    }

    if (axisViews.yAxis.value !== "erg") {
      if (newExcludedOptions !== "") newExcludedOptions += "&";
      newExcludedOptions += `y_axis=${axisViews.yAxis.value}`;
    }

    dispatch(setExcludedOptions(newExcludedOptions));

    try {
      const response = await axios.get(
        `/freq_flux_data/${uuid}?${newExcludedOptions}`
      );

      dispatch(setCoordinates(response.data.data.range_data));
      dispatch(setMinMaxCoordinates(response.data.data.min_max));

      if (resetGroupedLegends) {
        dispatch(
          setGroupedLegends(transformData(response.data.data.range_data))
        );
      }
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const fetchCSVFile = createAsyncThunk(
  "dataAccess/fetchCSVFile",
  async (_, { rejectWithValue, getState }) => {
    const { uuid, excludedOptions } = getState().dataAccess;
    try {
      const response = await axios.get(`/csv/${uuid}?${excludedOptions}`);

      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// slice
export const dataAccessSlice = createSlice({
  name: "dataAccess",
  initialState,
  reducers: {
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setSourceName: (state, action) => {
      state.sourceName = action.payload;
    },
    setSearchResults: (state, action) => {
      state.searchResults = action.payload;
    },
    setCoordinates: (state, action) => {
      state.coordinates = action.payload;
    },
    setMinMaxCoordinates: (state, action) => {
      state.minMaxCoordinates = action.payload;
    },
    setShowSourceInfo: (state, action) => {
      state.showSourceInfo = action.payload;
    },
    setSourceInfo: (state, action) => {
      state.sourceInfo = action.payload;
    },
    setRaDecValue: (state, action) => {
      state.raDecValue = action.payload;
    },
    setValueDB: (state, action) => {
      state.valueDB = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSearchTextLoading: (state, action) => {
      state.isSearchTextLoading = action.payload;
    },
    setShowSections: (state, action) => {
      state.showSections = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setOptionsError: (state, action) => {
      state.optionsError = action.payload;
    },
    setGroupedLegends: (state, action) => {
      state.groupedLegends = action.payload;
    },
    setSearchResultsIndex: (state, action) => {
      state.searchResultsIndex = action.payload;
    },
    setForceRun: (state, action) => {
      state.forceRun = action.payload;
    },
    setUuid: (state, action) => {
      state.uuid = action.payload;
    },
    setFilterFormState: (state, action) => {
      state.filterFormState = action.payload;
    },
    setShowPlotLoader: (state, action) => {
      state.showPlotLoader = action.payload;
    },
    setPlotDataErrorText: (state, action) => {
      state.plotDataErrorText = action.payload;
    },
    setRelatedArticles: (state, action) => {
      state.relatedArticles = action.payload;
    },
    setSearchResultsForView: (state, action) => {
      state.searchResultsForView = action.payload;
    },
    setRelatedArticlesError: (state, action) => {
      state.relatedArticlesError = action.payload;
    },
    setIsLoadingRelatedArticles: (state, action) => {
      state.isLoadingRelatedArticles = action.payload;
    },
    setAxisViews: (state, action) => {
      state.axisViews = action.payload;
    },
    setMjdRange: (state, action) => {
      state.mjdRange = action.payload;
    },
    setFilterDates: (state, action) => {
      state.filterDates = action.payload;
    },
    setExcludedOptions: (state, action) => {
      state.excludedOptions = action.payload;
    },
    setExcludedRanges: (state, action) => {
      state.excludedRanges = action.payload;
    },
    setDataInKnown: (state, action) => {
      state.dataInKnown = action.payload;
    },
    setDataGammaRay: (state, action) => {
      state.dataGammaRay = action.payload;
    },
    setLogs: (state, action) => {
      state.logs = action.payload;
    },
    setAladinText: (state, action) => {
      state.aladinText = action.payload;
    },
    resetVideo: (state) => {
      if (state.videoUrl) {
        URL.revokeObjectURL(state.videoUrl);
      }
      state.videoUrl = "";
      state.isLoadingVideo = false;
      state.errorVideo = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSearchResults.pending, (state) => {
        state.isSearchTextLoading = true;
        state.error = null;
      })
      .addCase(fetchSearchResults.fulfilled, (state, action) => {
        state.isSearchTextLoading = false;
        if (action.payload) {
          state.searchResults = action.payload.searchResults;
          state.searchResultsForView = action.payload.searchResultsForView;
        } else {
          state.searchResults = [];
        }
      })
      .addCase(fetchSearchResults.rejected, (state, action) => {
        state.isSearchTextLoading = false;
        if (action.payload && action.payload.status === 503) {
          state.optionsError = "Service unavailable";
        }
        console.error("Error fetching search results:", action.error);
      })
      .addCase(fetchCoordinates.pending, (state) => {
        state.error = null;
      })
      .addCase(fetchCoordinates.fulfilled, (state, action) => {
        state.coordinates = action.payload.data.range_data;
        state.minMaxCoordinates = action.payload.data.min_max;
        state.groupedLegends = transformData(action.payload.data.range_data);
        state.showPlotLoader = false;
      })
      .addCase(fetchCoordinates.rejected, (state, action) => {
        state.error = action.payload;
        console.error("Error fetching coordinates:", action.error);
      })
      .addCase(fetchArticlesAPI.pending, (state) => {
        state.isLoadingRelatedArticles = true;
        state.relatedArticlesError = null;
      })
      .addCase(fetchArticlesAPI.fulfilled, (state, action) => {
        state.isLoadingRelatedArticles = false;
        state.relatedArticles = action.payload;
      })
      .addCase(fetchArticlesAPI.rejected, (state, action) => {
        state.isLoadingRelatedArticles = false;
        state.relatedArticlesError = action.payload;
      })
      .addCase(fetchCSVFile.fulfilled, (state, action) => {
        triggerCSVDownload(action.payload, `${state.sourceName}.csv`);
      })
      .addCase(fetchSedLcVideo.pending, (state) => {
        state.isLoadingVideo = true;
        state.errorVideo = null;
      })
      .addCase(fetchSedLcVideo.fulfilled, (state, action) => {
        state.isLoadingVideo = false;
        state.videoUrl = action.payload;
      })
      .addCase(fetchSedLcVideo.rejected, (state, action) => {
        state.isLoadingVideo = false;
        state.videoUrl = "";

        if (action.payload === 404) {
          state.errorVideo =
            "The SED/LC animation is not available for this source";
        } else {
          state.errorVideo = "Error loading video.";
        }
      });
  },
});

export const {
  setUuid,
  setSearchText,
  setSourceName,
  setSearchResults,
  setCoordinates,
  setMinMaxCoordinates,
  setShowSourceInfo,
  setSourceInfo,
  setRaDecValue,
  setValueDB,
  setLoading,
  setAladinText,
  setSearchTextLoading,
  setIsLoadingRelatedArticles,
  setShowSections,
  setError,
  setOptionsError,
  setGroupedLegends,
  setForceRun,
  setShowPlotLoader,
  setPlotDataErrorText,
  setRelatedArticles,
  setSearchResultsForView,
  setRelatedArticlesError,
  setFilterFormState,
  setSearchResultsIndex,
  setAxisViews,
  setMjdRange,
  setFilterDates,
  setExcludedOptions,
  setExcludedRanges,
  setDataInKnown,
  setDataGammaRay,
  setLogs,
  resetVideo,
} = dataAccessSlice.actions;

export const selectUuid = (state) => state.dataAccess.uuid;
export const selectShowSections = (state) => state.dataAccess.showSections;
export const selectShowSourceInfo = (state) => state.dataAccess.showSourceInfo;
export const selectSourceInfo = (state) => state.dataAccess.sourceInfo;
export const selectError = (state) => state.dataAccess.error;
export const selectSearchText = (state) => state.dataAccess.searchText;
export const selectSearchResults = (state) => state.dataAccess.searchResults;
export const selectSearchResultsForView = (state) =>
  state.dataAccess.searchResultsForView;
export const selectSearchResultsIndex = (state) =>
  state.dataAccess.searchResultsIndex;
export const selectOptionsError = (state) => state.dataAccess.optionsError;
export const selectLoading = (state) => state.dataAccess.isLoading;
export const selectRaDecValue = (state) => state.dataAccess.raDecValue;
export const selectValueDb = (state) => state.dataAccess.valueDB;
export const selectForceRun = (state) => state.dataAccess.forceRun;
export const selectCoordinates = (state) => state.dataAccess.coordinates;
export const selectMinMaxCoordinates = (state) =>
  state.dataAccess.minMaxCoordinates;
export const selectSourceName = (state) => state.dataAccess.sourceName;
export const selectGroupedLegends = (state) => state.dataAccess.groupedLegends;
export const selectShowPlotLoader = (state) => state.dataAccess.showPlotLoader;
export const selectRelatedArticles = (state) =>
  state.dataAccess.relatedArticles;
export const selectRelatedArticlesError = (state) =>
  state.dataAccess.relatedArticlesError;
export const selectIsLoadingRelatedArticles = (state) =>
  state.dataAccess.isLoadingRelatedArticles;
export const selectPlotDataErrorText = (state) =>
  state.dataAccess.plotDataErrorText;
export const selectAxisViews = (state) => state.dataAccess.axisViews;
export const selectMjdRange = (state) => state.dataAccess.mjdRange;
export const selectFilterDates = (state) => state.dataAccess.filterDates;
export const selectDataInKnown = (state) => state.dataAccess.dataInKnown;
export const selectDataGammaRay = (state) => state.dataAccess.dataGammaRay;
export const selectLogs = (state) => state.dataAccess.logs;
export const selectSearchTextLoading = (state) =>
  state.dataAccess.isSearchTextLoading;
export const selectSedLcVideoUrl = (state) => state.dataAccess.videoUrl;
export const selectSedLcIsLoading = (state) => state.dataAccess.isLoadingVideo;
export const selectSedLcError = (state) => state.dataAccess.errorVideo;
export const selectAladinText = (state) => state.dataAccess.aladinText;

export default dataAccessSlice.reducer;
