import { useState } from "react";
import { Link } from "react-scroll";
import { ReactComponent as ListIcon } from "bootstrap-icons/icons/list.svg";
import { ReactComponent as XIcon } from "bootstrap-icons/icons/x.svg";
import { LinkIcon } from "../assets/images/svg.js";
import styles from "../styles/navbar.module.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState();

  const handleClick = (e) => {
    setIsOpen((prevState) => !prevState);
  };

  const handleLinkClick = (e) => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  return (
    <header id="header" className="d-flex align-items-center fixed-top">
      <div className="container d-flex align-items-center justify-content-between">
        <h1 className="logo">
          <Link to="hero" spy={true} smooth={true} offset={-70} duration={0}>
            MMDC
          </Link>
        </h1>
        <nav id="navbar" className={`navbar ${isOpen ? "navbar-mobile" : ""}`}>
          <ul>
            <li>
              <Link
                className="nav-link scrollto"
                to="hero"
                spy={true}
                smooth={true}
                offset={-70}
                duration={0}
                onClick={handleLinkClick}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                className="nav-link scrollto"
                to="about"
                spy={true}
                smooth={true}
                offset={-70}
                duration={0}
                onClick={handleLinkClick}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                className="nav-link scrollto"
                to="services"
                spy={true}
                smooth={true}
                offset={-70}
                duration={0}
                onClick={handleLinkClick}
              >
                Data access
              </Link>
            </li>
            <li>
              <Link
                className="nav-link scrollto"
                to="theoreticalModeling"
                spy={true}
                smooth={true}
                offset={-70}
                duration={0}
                onClick={handleLinkClick}
              >
                Theoretical Modeling
              </Link>
            </li>
            <li>
              <Link
                className="nav-link scrollto"
                to="articles"
                spy={true}
                smooth={true}
                offset={-70}
                duration={0}
                onClick={handleLinkClick}
              >
                Articles
              </Link>
            </li>
            <li>
              <Link
                className="nav-link scrollto"
                to="team"
                spy={true}
                smooth={true}
                offset={-70}
                duration={0}
                onClick={handleLinkClick}
              >
                Team
              </Link>
            </li>
            <li>
              <Link
                className="nav-link scrollto"
                to="contact"
                spy={true}
                smooth={true}
                offset={-70}
                duration={0}
                onClick={handleLinkClick}
              >
                Contact
              </Link>
            </li>
          </ul>
          {isOpen ? (
            <XIcon
              className="bi mobile-nav-toggle bi-x"
              width={28}
              height={28}
              onClick={handleClick}
            />
          ) : (
            <ListIcon
              className="bi mobile-nav-toggle bi-list"
              width={28}
              height={28}
              onClick={handleClick}
            />
          )}
        </nav>

        <div>
          <a
            href="http://firmamento.hosting.nyu.edu/"
            target="_blank"
            rel="noreferrer"
            className={styles.rightLinks}
          >
            <span>FIRMAMENTO</span>
            <LinkIcon />
          </a>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
