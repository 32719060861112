import React, { useEffect, useRef } from "react";
import aboutUsMp4 from "../assets/images/aboutUs.mp4";
import { ReactComponent as CheckCircleIcon } from "bootstrap-icons/icons/check-circle.svg";
import WhyUs from "./WhyUs";

const AboutUs = () => {
  const videoRef = useRef(null);

  const handleScroll = () => {
    if (videoRef.current) {
      const rect = videoRef.current.getBoundingClientRect();
      if (rect.top <= window.innerHeight && rect.bottom >= 0) {
        videoRef.current.play().catch((error) => {
          // Handle the error here, e.g., show a play button
          console.error("Video play failed:", error);
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section
      name="about"
      id="about"
      className="about bg-light"
      style={{ minHeight: "100vh" }}
    >
      <div className="container">
        <div className="row" style={{ alignItems: "center" }}>
          <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left">
            <video ref={videoRef} width="100%" height="364" controls muted>
              <source src={aboutUsMp4} type="video/mp4" />
              Your browser does not support the video tag
            </video>
          </div>
          <div>
            <h3 className="content-title">
              MMDC is an innovative platform that facilitates comprehensive
              research on blazars by building and analyzing multiwavelength
              Spectral Energy Distributions (SEDs).
            </h3>
          </div>
          <div
            className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
            data-aos="fade-right"
          >
            <p className="fst-italic">
              Blazars are a subtype of active galactic nuclei with relativistic
              jets directed almost exactly towards Earth. As powerful,
              long-lived extragalactic objects, they offer a unique window into
              the processes around supermassive black holes and the distant
              universe.
            </p>
            <ul>
              <li>
                <CheckCircleIcon width="22" height="22" color="red" />{" "}
                Originating near supermassive black holes, the relativistic jets
                of blazars produce intense emissions spanning from radio
                frequencies to gamma-rays.
              </li>
              <li>
                <CheckCircleIcon width="22" height="22" color="red" /> The
                broadband emission from blazars shows high variability across
                the electromagnetic spectrum, often manifesting on timescales of
                days or hours and displaying complex behavior.
              </li>
              <li>
                <CheckCircleIcon width="22" height="22" color="red" />{" "}
                High-energy neutrino detection from blazars confirms hadronic
                processes within their jets, where protons interact with matter
                or radiation to create neutrinos. This makes blazars key targets
                for multi-messenger studies.
              </li>
            </ul>
            In this database, users can search for and construct detailed
            multi-wavelength SEDs of blazars. SEDs are interactively visualized,
            allowing data manipulation and time filtering. The SEDs are
            constructed by combining archival data from various catalogs
            (obtained through the{" "}
            <a
              href="https://www.sciencedirect.com/science/article/abs/pii/S2213133719301507?via%3Dihub"
              rel="noreferrer"
              target="_blank"
            >
              VOU-Blazar
            </a>{" "}
            tool) with time-resolved data across IR, optical, UV, X-ray, and
            gamma-ray bands.
          </div>
        </div>
      </div>

      <WhyUs />
    </section>
  );
};

export default AboutUs;
