import React from "react";
import David from "../assets/images/team/David.jpg";
import Gevorg from "../assets/images/team/Gevorg.jpg";
import Manvel from "../assets/images/team/Manvel.jpg";
import Mher from "../assets/images/team/Mher.jpg";
import DrHusne from "../assets/images/team/Dr_Husne.jpg";
import DrDamien from "../assets/images/team/Dr_Damien.jpg";
import Narek from "../assets/images/team/Narek.jpg";
import Sargis from "../assets/images/team/Sargis.jpg";
import Vazgen from "../assets/images/team/Vazgen.jpg";
import Paolo from "../assets/images/team/Paolo.jpg";

const teamData = [
  {
    name: "Dr. Narek Sahakyan",
    position: "Director, ICRANet Armenia Center IO",
    description:
      "Multiwavelength & Multimessenger Astrophysics, Theoretical Modeling, Data Analysis",
    image: Narek,
  },
  {
    name: "Dr. Paolo Giommi",
    position: "Associated scientists INAF, IAS-TUM and CASS-NYUAD",
    description:
      "Extragalactic astrophysics, multi-frequency multi-messenger studies of blazars, Data Analysis",
    image: Paolo,
  },
  {
    name: "Vazgen Vardanyan",
    position: "Researcher, ICRANet Armenia",
    description: "Application of ML in blazar research",
    image: Vazgen,
  },

  {
    name: "Dr. Damien Bégué",
    position: "Postdoc, Bar Ilan University",
    description:
      "Theorist, gamma-ray burst expert, radiative transfer enthusiast, magneto-hydrodynamics connoisseur, numerical simulation aficionado.",
    image: DrDamien,
  },
  {
    name: "Dr. Sargis Gasparyan",
    position: "Head of Group, ICRANet Armenia",
    description: "Modeling of Emission Processes in Blazar Jets",
    image: Sargis,
  },
  {
    name: "Dr. David Israyelyan",
    position: "Researcher, ICRANet Armenia",
    description: "Multiwavelength Data Analysis (coding)",
    image: David,
  },
  {
    name: "Dr. Gevorg Harutyunyan",
    position: "Researcher, ICRANet Armenia",
    description: "Multiwavelength Data Analysis (coding)",
    image: Gevorg,
  },
  {
    name: "Manvel Manvelyan",
    position: "PhD Student, ICRANet Armenia",
    description: "Multiwavelength Data Correlation Analysis",
    image: Manvel,
  },
  {
    name: "Mher Khachatryan",
    position: "PhD Student, ICRANet Armenia",
    description: "Application of ML in blazar research",
    image: Mher,
  },
  {
    name: "Dr. Hüsne Dereli-Bégué",
    position: "",
    description:
      "Multiwavelength Data Analysis of Blazars and Gamma-ray bursts.",
    image: DrHusne,
  },
];

const OurTeam = () => {
  return (
    <section id="team" className="team bg-light" style={{ minHeight: "100vh" }}>
      <div className="container">
        <div className="section-title">
          <span>Team</span>
          <h2>Team</h2>
        </div>

        <div className="row">
          {teamData.map((member, index) => (
            <div
              key={index}
              className="col-lg-3 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
            >
              <div className="member">
                <img src={member.image} alt={member.name} />
                <h4>{member.name}</h4>
                <span>{member.position}</span>
                <p>{member.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
