import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axiosConfig";

// async thunk
export const fetchArticlesAsync = createAsyncThunk(
  "articles/fetch",
  async ({ inputValue, value, startYear, endYear }, thunkAPI) => {
    try {
      const matches = inputValue.match(
        /(\b\w+=\[[^\]]*\]|\b\w+="[^"]*"|\b\w+=[^ ]*)/g
      );

      const formattedSourceName = matches ? matches.join("&") : inputValue;

      const response = await axios.get(
        `/articles/?search_type=${value}&query_text=${encodeURIComponent(
          formattedSourceName
        )}&start_year=${startYear}&end_year=${endYear}`
      );

      return response.data?.response?.docs || [];
    } catch (error) {
      console.error("Failed to fetch articles:", error);
      throw error;
    }
  }
);

// slice
export const articlesSlice = createSlice({
  name: "articles",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
    inputValue: "",
    showArticles: false,
    isSubmitted: false,
  },
  reducers: {
    setInputValue: (state, action) => {
      state.inputValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchArticlesAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.showArticles = true;
        state.isSubmitted = true;
      })
      .addCase(fetchArticlesAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.showArticles = !!action.payload.length;
      })
      .addCase(fetchArticlesAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        state.showArticles = false;
      });
  },
});

export const { setInputValue } = articlesSlice.actions;

export const selectArticles = (state) => state.articles;

export default articlesSlice.reducer;
