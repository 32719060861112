// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sedPlot_wrapper__40oK1 {\n    padding: 20px;\n    margin-top: 40px;\n    margin: 40px auto 0;\n    background: rgba(255, 255, 255, 0.7);\n    border-radius: 19px;\n    display: flex;\n    flex-direction: column;\n    row-gap: 26px;\n    box-shadow: 0 0 30px rgba(115, 115, 115, 0.5);\n}\n\n.sedPlot_searchMethodContentWrapper__08NEP {\n    display: flex;\n    column-gap: 26px;\n}\n\n.sedPlot_errorText__Lg87s {\n    margin: 20px 0px 0px 0px;\n    color: #d42626;\n    font-weight: bold;\n    text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/styles/sedPlot.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,mBAAmB;IACnB,oCAAoC;IACpC,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,6CAA6C;AACjD;;AAEA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,wBAAwB;IACxB,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".wrapper {\n    padding: 20px;\n    margin-top: 40px;\n    margin: 40px auto 0;\n    background: rgba(255, 255, 255, 0.7);\n    border-radius: 19px;\n    display: flex;\n    flex-direction: column;\n    row-gap: 26px;\n    box-shadow: 0 0 30px rgba(115, 115, 115, 0.5);\n}\n\n.searchMethodContentWrapper {\n    display: flex;\n    column-gap: 26px;\n}\n\n.errorText {\n    margin: 20px 0px 0px 0px;\n    color: #d42626;\n    font-weight: bold;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "sedPlot_wrapper__40oK1",
	"searchMethodContentWrapper": "sedPlot_searchMethodContentWrapper__08NEP",
	"errorText": "sedPlot_errorText__Lg87s"
};
export default ___CSS_LOADER_EXPORT___;
