// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dataPolicy_dataPolicyBtn__jWng0 {\n    background-color: #ffffff !important;\n    color: #000000 !important;\n    padding: 12px 24px;\n    width: max-content;\n}\n\n.dataPolicy_dataPolicyBtn__jWng0:hover {\n    background-color: #b9b9b9 !important;\n}", "",{"version":3,"sources":["webpack://./src/styles/dataPolicy.module.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":[".dataPolicyBtn {\n    background-color: #ffffff !important;\n    color: #000000 !important;\n    padding: 12px 24px;\n    width: max-content;\n}\n\n.dataPolicyBtn:hover {\n    background-color: #b9b9b9 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dataPolicyBtn": "dataPolicy_dataPolicyBtn__jWng0"
};
export default ___CSS_LOADER_EXPORT___;
