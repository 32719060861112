import React from "react";
import { Button } from "@mui/material";
import { selectBestParametersLink } from "../features/theoreticalModeling/theoreticalModelingSlice";
import { useSelector } from "react-redux";
import styles from "../styles/searchPlotData.module.css";

const BestParameters = () => {
  const bestParametersLink = useSelector(selectBestParametersLink);

  return (
    <div className={styles.bestParametersWrapper}>
      <Button
        component="a"
        href={bestParametersLink}
        download
        variant="contained"
        color="primary"
        size="small"
        fullWidth
        disabled={!bestParametersLink}
        className={styles.bestParameters}
      >
        Best Parameters
      </Button>
    </div>
  );
};

export default BestParameters;
