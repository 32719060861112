import {
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSearchParams,
  setSscSearchParams,
} from "../features/theoreticalModeling/theoreticalModelingSlice";
import styles from "../styles/sscInputs.module.css";

const SSCInputs = ({ inputErrors, handleSscSearchChange }) => {
  const dispatch = useDispatch();
  const { sscInputsData } = useSelector(selectSearchParams);

  return (
    <div className={styles.inputsWrapper}>
      {sscInputsData.map(
        ({ id, value, label, name, checked, isWithCheckbox = false }) => {
          const isMin = name === "log_gamma_min";
          const isMax = name === "log_gamma_cut";

          if (label === "EBL") {
            return (
              <FormControlLabel
                key={id}
                sx={{
                  margin: "6px 0 0",
                  width: "48%",
                  justifyContent: "center",
                  alignSelf: "flex-start",
                  order: 10,
                }}
                control={
                  <Checkbox
                    sx={{ padding: 0 }}
                    checked={value}
                    onChange={(e) =>
                      dispatch(
                        setSscSearchParams({
                          id: id,
                          value: e.target.checked,
                        })
                      )
                    }
                  />
                }
                label={
                  <Typography
                    align="left"
                    variant="body1"
                    style={{ userSelect: "none", marginLeft: "0" }}
                  >
                    {label}
                  </Typography>
                }
              />
            );
          }

          const CustomLabel = ({ isMin, isMax, name }) => {
            if (isMin || isMax) {
              return (
                <span>
                  log10(γ
                  <span style={{ fontSize: "smaller" }}>
                    {isMin ? "min" : "max"}
                  </span>
                  )
                </span>
              );
            } else {
              return <span>{name}</span>;
            }
          };

          return (
            <div
              key={id}
              className={`${styles.textField} ${
                isWithCheckbox && styles.withCheckbox
              }`}
            >
              <div>
                <TextField
                  label={
                    <CustomLabel isMin={isMin} isMax={isMax} name={label} />
                  }
                  variant="outlined"
                  size="small"
                  value={value}
                  onChange={(e) => handleSscSearchChange(e, label, id)}
                  error={!!inputErrors[label]}
                />
                <p className={styles.inputsErrorText}>
                  {!!inputErrors[label] ? inputErrors[label] : ""}
                </p>
              </div>
              {isWithCheckbox && (
                <Checkbox
                  sx={{ padding: 0, marginTop: "6px" }}
                  checked={checked}
                  onChange={(e) =>
                    dispatch(
                      setSscSearchParams({
                        id,
                        checkbox: e.target.checked,
                      })
                    )
                  }
                />
              )}
            </div>
          );
        }
      )}
    </div>
  );
};

export default SSCInputs;
