// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dataSection_dataSectionWrapper__g7P1N {\n  min-height: 600px;\n  margin-top: 26px;\n}\n\n.dataSection_plotTabPanel__5edqf > div {\n  padding: 20px 0px 0px 0px;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/dataSection.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".dataSectionWrapper {\n  min-height: 600px;\n  margin-top: 26px;\n}\n\n.plotTabPanel > div {\n  padding: 20px 0px 0px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dataSectionWrapper": "dataSection_dataSectionWrapper__g7P1N",
	"plotTabPanel": "dataSection_plotTabPanel__5edqf"
};
export default ___CSS_LOADER_EXPORT___;
