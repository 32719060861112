import TheoreticalModelingPlot from "../../components/TheoreticalModelingPlot";

const TheoreticalModeling = () => {
  return (
    <section
      id="theoreticalModeling"
      name="theoreticalModeling"
      className="theoreticalModeling bg-light"
      style={{ minHeight: "100vh" }}
    >
      <div className="container">
        <div className="section-title">
          <h2>
            Modeling blazar broadband emission with convolutional neural
            networks
          </h2>
        </div>

        <div className="row">
          <div className="mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="300">
            <TheoreticalModelingPlot />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TheoreticalModeling;
