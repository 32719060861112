import React from "react";
import {
  Button,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import styles from "../styles/dataPolicy.module.css";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const DataPolicy = () => {
  return (
    <div>
      <CustomWidthTooltip
        title={
          <Typography sx={{ textAlign: "center" }}>
            MMDC provides data from different catalogs, online tools, and data
            analyzed in ICRANet Armenia. Each downloaded data file will contain
            the necessary references for appropriate citation.
          </Typography>
        }
      >
        <Button
          className={styles.dataPolicyBtn}
          variant="contained"
          onClick={(e) => e.preventDefault()}
          sx={{ columnGap: "6px" }}
        >
          Data Policy
          <InfoOutlinedIcon fontSize="small" />
        </Button>
      </CustomWidthTooltip>
    </div>
  );
};

export default DataPolicy;
