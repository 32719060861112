import React, { useState, useEffect, useMemo } from "react";
import Plot from "react-plotly.js";
import PuffLoader from "react-spinners/PuffLoader";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import {
  selectAxisViews,
  selectCoordinates,
  selectMinMaxCoordinates,
  selectPlotDataErrorText,
  selectRaDecValue,
  selectShowPlotLoader,
  selectShowSourceInfo,
  selectSourceName,
} from "../features/dataAccess/dataAccessSlice";
import FilterPlotData from "./FilterPlotData";
import SourceInfo from "./SourceInfo";
import styles from "../styles/plotComponent.module.css";

const PlotComponent = () => {
  const [minMax, setMinMax] = useState({});
  const coordinates = useSelector(selectCoordinates);
  const minMaxCoordinates = useSelector(selectMinMaxCoordinates);
  const sourceName = useSelector(selectSourceName);
  const raDecValue = useSelector(selectRaDecValue);
  const showPlotLoader = useSelector(selectShowPlotLoader);
  const plotDataErrorText = useSelector(selectPlotDataErrorText);
  const axisViews = useSelector(selectAxisViews);
  const showSourceInfo = useSelector(selectShowSourceInfo);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  const cssOverride = useMemo(
    () => ({
      margin: "0 auto",
      top: "35%",
    }),
    []
  );

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 992);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const updatedDataAndAnnotations = useMemo(() => {
    let updatedData = [];
    let annotations = [];

    Object.keys(coordinates).forEach((outerKey) => {
      Object.keys(coordinates[outerKey]).forEach((innerKey) => {
        const itemOther = coordinates[outerKey][innerKey].Other;
        const itemUL = coordinates[outerKey][innerKey].UL;

        updatedData.push(
          {
            name: `${outerKey} - ${innerKey}`,
            x: itemOther?.freq,
            y: itemOther?.flux,
            type: "scattergl",
            mode: "markers",
            marker: { color: itemOther?.color, size: 7 },
            showlegend: false,
            hoverinfo: "x+y",
            error_y: {
              type: "data",
              array: itemOther?.err_flux,
              width: 0,
            },
          },
          {
            x: itemUL?.freq,
            y: itemUL?.flux,
            showlegend: false,
            mode: "none",
            hoverinfo: "x+y",
          }
        );

        itemUL?.freq?.forEach((freq, index) => {
          if (itemUL.flux[index]) {
            updatedData.push({
              x: [freq],
              y: [itemUL.flux[index]],
              mode: "none",
              marker: {
                color: itemUL?.color,
              },
              showlegend: false,
              hoverinfo: "x+y",
            });

            annotations.push({
              x: Math.log10(freq),
              y: Math.log10(itemUL.flux[index]),
              xref: "x",
              yref: "y",
              text: "",
              showarrow: true,
              arrowhead: 1,
              arrowsize: 1,
              arrowwidth: 1.6,
              arrowcolor: itemUL?.color,
              ax: 0,
              ay: -20,
              yshift: -20,
              annotations: "none",
            });
          }
        });
      });
    });

    return { updatedData, annotations };
  }, [coordinates]);

  const [state, setState] = useState({
    data: [],
    layout: {
      autosize: true,
      shapes: [
        {
          type: "rect",
          xref: "paper",
          yref: "paper",
          x0: 0,
          y0: 0,
          x1: 1,
          y1: 1,
          line: {
            color: "black",
            width: 1,
          },
        },
      ],
      legend: {
        title: {
          text: "Catalog",
        },
        itemsizing: "constant",
        tracegroupgap: 10,
      },
      xaxis: {
        type: "log",
        autorange: false,
        exponentformat: "power",
        ticks: "inside",
        tickcolor: "black",
        tickfont: {
          size: 16,
        },
        tickmode: "linear",
        dtick: 1,
        title: {
          text: `𝜈${axisViews.xAxis.label[0]}`,
          font: {
            size: 18,
          },
        },
      },
      yaxis: {
        type: "log",
        autorange: false,
        exponentformat: "power",
        ticks: "inside",
        tickcolor: "black",
        tickfont: {
          size: 16,
        },
        tickmode: "linear",
        dtick: 1,
        title: {
          text: `${axisViews.yAxis.label[0]}`,
          font: {
            size: 18,
          },
        },
      },
    },
    frames: [],
    config: {},
  });

  const xAxisIndex = useMemo(
    () =>
      axisViews.xAxis.name.findIndex((name) => name === axisViews.xAxis.value),
    [axisViews]
  );

  const yAxisIndex = useMemo(
    () =>
      axisViews.yAxis.name.findIndex((name) => name === axisViews.yAxis.value),
    [axisViews]
  );

  useEffect(() => {
    const minX = minMaxCoordinates?.freq[0]
      ? Math.log10(minMaxCoordinates.freq[0])
      : 7;
    const maxX = minMaxCoordinates?.freq[1]
      ? Math.log10(minMaxCoordinates.freq[1])
      : 26;
    const minY = minMaxCoordinates?.flux[0]
      ? Math.log10(minMaxCoordinates.flux[0])
      : -14;
    const maxY = minMaxCoordinates?.flux[1]
      ? Math.log10(minMaxCoordinates.flux[1])
      : -8;

    setMinMax({ minX, minY, maxX, maxY });
  }, [minMaxCoordinates]);

  useEffect(() => {
    const { minX, minY, maxX, maxY } = minMax;
    const { updatedData, annotations } = updatedDataAndAnnotations;

    const xAxisLabel = axisViews.xAxis.label[xAxisIndex];
    const yAxisLabel = axisViews.yAxis.label[yAxisIndex];
    const tickValsX = [];
    const tickValsY = [];

    for (let i = Math.ceil(minX); i <= Math.floor(maxX); i++) {
      tickValsX.push(i);
    }
    for (let i = Math.ceil(minY); i <= Math.floor(maxY); i++) {
      tickValsY.push(i);
    }

    const tickTextX = tickValsX.map((v) => `10^${v}`);

    const tickTextY = tickValsY.map((v) => `10^${v}`);

    setState((prevState) => ({
      ...prevState,
      data: updatedData,
      layout: {
        ...prevState.layout,
        margin: {
          b: isMobile ? 40 : 160,
          t: 50,
          r: isMobile ? 0 : 250,
        },
        annotations: annotations,
        xaxis: {
          ...prevState.layout.xaxis,
          domain: [0, 0.975],
          title: {
            ...prevState.layout.xaxis.title,
            text: `${
              xAxisLabel !== "eV"
                ? "𝜈<sub>obs</sub>[" + xAxisLabel + "]"
                : "E<sub>obs</sub>[" + xAxisLabel + "]"
            }`,
            standoff: 10,
          },
          range: [minX, maxX],
          tickmode: "auto",
          tickvals: tickValsX,
          ticktext: tickTextX,
        },
        yaxis: {
          ...prevState.layout.yaxis,
          domain: [0, 0.955],
          title: {
            ...prevState.layout.yaxis.title,
            text: `${yAxisLabel}`,
          },
          range: [minY, maxY],
          tickmode: "linear",
          tickvals: tickValsY,
          ticktext: tickTextY,
        },
      },
    }));
  }, [
    updatedDataAndAnnotations,
    sourceName,
    raDecValue.ra,
    raDecValue.dec,
    xAxisIndex,
    yAxisIndex,
    axisViews,
    minMax,
    isMobile,
  ]);

  return (
    <div>
      <div
        className={`${styles.plotComponentWrapper} ${
          state.data.length ? styles.toggleBackgroundColor : ""
        }`}
      >
        <div className={styles.plotlyPlotWrapper}>
          <Plot
            data={state.data}
            layout={state.layout}
            config={{ doubleClick: "autosize" }}
            useResizeHandler
            className={styles.plotlyPlot}
          />
          {showPlotLoader && (
            <PuffLoader
              className={styles.puffLoader}
              color="#36d7b7"
              cssOverride={cssOverride}
              loading
              size={100}
              speedMultiplier={3}
            />
          )}
          {plotDataErrorText && (
            <Typography className={styles.plotDataErrorText}>
              {plotDataErrorText}
            </Typography>
          )}
        </div>

        {!isMobile && <FilterPlotData />}
      </div>

      <div className={styles.mobileFilterInfoWrapper}>
        {isMobile && <FilterPlotData />}
        {showSourceInfo && <SourceInfo />}
      </div>
    </div>
  );
};

export default PlotComponent;
