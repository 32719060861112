import { Fragment } from "react";
import { useSelector } from "react-redux";
import PuffLoader from "react-spinners/PuffLoader";
import {
  selectRelatedArticles,
  selectRelatedArticlesError,
  selectIsLoadingRelatedArticles,
} from "../features/dataAccess/dataAccessSlice";
import styles from "../styles/relatedArticles.module.css";

const RelatedArticles = () => {
  const relatedArticles = useSelector(selectRelatedArticles);
  const relatedArticlesError = useSelector(selectRelatedArticlesError);
  const isLoading = useSelector(selectIsLoadingRelatedArticles);

  return (
    <div className={styles.relatedArticlesWrapper}>
      <div>
        {!!relatedArticles.length &&
          relatedArticles.map(({ title, year, author, link }) => (
            <div key={link} className={styles.articleWrapper}>
              <div>
                <a href={link} target="_blank" rel="noreferrer">
                  {title}
                </a>
                <p>{year}</p>
              </div>
              <p>
                {author?.map((item, index) => (
                  <Fragment key={index}>
                    <span>{item}</span>
                    {index !== author.length - 1 && <span>, </span>}
                  </Fragment>
                ))}
              </p>
            </div>
          ))}

        {isLoading && (
          <PuffLoader
            className={styles.puffLoader}
            color="#36d7b7"
            cssOverride={{ margin: "0 auto" }}
            loading
            size={100}
            speedMultiplier={3}
          />
        )}

        {!isLoading &&
          !relatedArticles.length &&
          relatedArticlesError === null && (
            <p className={styles.noArticles}>No Related Articles</p>
          )}

        {relatedArticlesError && (
          <p className={styles.errorText}>{relatedArticlesError}</p>
        )}
      </div>
    </div>
  );
};

export default RelatedArticles;
