// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TMSourceInfo_contentWrapper__ErrJo {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.TMSourceInfo_citationBtn__60JHp {\n  background-color: #ffffff !important;\n  color: #000000 !important;\n  width: max-content;\n}\n\n.TMSourceInfo_citationBtn__60JHp:hover {\n  background-color: #b9b9b9 !important;\n}\n\n.TMSourceInfo_modelDescription__JyVFt:hover {\n  color: #fff;\n}\n\n.TMSourceInfo_infoLink__A-0Yf {\n  color: #00c4f5;\n}\n\n.TMSourceInfo_infoLink__A-0Yf:hover {\n  color: rgb(0, 217, 255);\n}\n\n.TMSourceInfo_feedback__H6AIM {\n  color: #1976d2;\n}\n\n.TMSourceInfo_feedback__H6AIM:hover {\n  color: #0080ff;\n}\n\n@media screen and (max-width: 768px) {\n  .TMSourceInfo_contentWrapper__ErrJo {\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 24px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/styles/TMSourceInfo.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;EACpC,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,sBAAsB;IACtB,uBAAuB;IACvB,SAAS;EACX;AACF","sourcesContent":[".contentWrapper {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.citationBtn {\n  background-color: #ffffff !important;\n  color: #000000 !important;\n  width: max-content;\n}\n\n.citationBtn:hover {\n  background-color: #b9b9b9 !important;\n}\n\n.modelDescription:hover {\n  color: #fff;\n}\n\n.infoLink {\n  color: #00c4f5;\n}\n\n.infoLink:hover {\n  color: rgb(0, 217, 255);\n}\n\n.feedback {\n  color: #1976d2;\n}\n\n.feedback:hover {\n  color: #0080ff;\n}\n\n@media screen and (max-width: 768px) {\n  .contentWrapper {\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentWrapper": "TMSourceInfo_contentWrapper__ErrJo",
	"citationBtn": "TMSourceInfo_citationBtn__60JHp",
	"modelDescription": "TMSourceInfo_modelDescription__JyVFt",
	"infoLink": "TMSourceInfo_infoLink__A-0Yf",
	"feedback": "TMSourceInfo_feedback__H6AIM"
};
export default ___CSS_LOADER_EXPORT___;
