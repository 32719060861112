import React from "react";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { fetchCSVFile } from "../features/dataAccess/dataAccessSlice";

const DownloadCSV = () => {
  const dispatch = useDispatch();

  const handleRequestForCSV = (e) => {
    dispatch(fetchCSVFile());
  };

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleRequestForCSV}
        style={{ width: "max-content" }}
      >
        Download CSV
      </Button>
    </div>
  );
};

export default DownloadCSV;
