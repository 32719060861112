// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filterDates_datesWrapper__scBce {\n    display: flex;\n    flex-direction: column;\n    row-gap: 6px;\n    padding-right: 8px;\n    min-width: 120px;\n    max-width: 180px;\n    width: 100%;\n    margin-top: 8px;\n}\n\n.filterDates_filterBtn__v8PGu {\n    display: block;\n    margin: 8px auto;\n}", "",{"version":3,"sources":["webpack://./src/styles/filterDates.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".datesWrapper {\n    display: flex;\n    flex-direction: column;\n    row-gap: 6px;\n    padding-right: 8px;\n    min-width: 120px;\n    max-width: 180px;\n    width: 100%;\n    margin-top: 8px;\n}\n\n.filterBtn {\n    display: block;\n    margin: 8px auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"datesWrapper": "filterDates_datesWrapper__scBce",
	"filterBtn": "filterDates_filterBtn__v8PGu"
};
export default ___CSS_LOADER_EXPORT___;
