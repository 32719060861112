import SedPlot from "../../components/SedPlot";

const DataAccess = () => {
  return (
    <section id="services" className="bg-light" style={{ minHeight: "100vh" }}>
      <div className="container">
        <div className="section-title">
          <h2>Search and Build time resolved SEDs</h2>
        </div>

        <div className="row">
          <div className="mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="300">
            <SedPlot />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DataAccess;
