import { useSelector } from "react-redux";
import DataSection from "./DataSection";
import SearchPlot from "./SearchPlot";
import styles from "../styles/sedPlot.module.css";
import { selectError } from "../features/dataAccess/dataAccessSlice";

const SedPlot = () => {
  const error = useSelector(selectError);

  return (
    <div className={styles.wrapper}>
      <SearchPlot />
      {error && <p className={styles.errorText}>{error}</p>}
      <DataSection />
    </div>
  );
};

export default SedPlot;
