import { Button } from "@mui/material";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectUploadCsvErrorText } from "../features/theoreticalModeling/theoreticalModelingSlice";
import styles from "../styles/searchPlotData.module.css";

const UploadCSVFile = ({ handleFileChange }) => {
  const uploadCsvErrorText = useSelector(selectUploadCsvErrorText);
  const fileInputRef = useRef(null);

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  return (
    <div className={styles.uploadCSVWrapper}>
      <input
        type="file"
        onChange={handleFileChange}
        style={{ display: "none" }}
        ref={fileInputRef}
      />
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={triggerFileInput}
      >
        Upload File
      </Button>
      {uploadCsvErrorText && (
        <p style={{ color: "red" }}>{uploadCsvErrorText}</p>
      )}
    </div>
  );
};

export default UploadCSVFile;
