import React, { useState } from "react";

import UploadCSVFile from "./UploadCSVFile";
import CornerPlot from "./CornerPlot";
import BestParameters from "./BestParameters";
import FitPlot from "./FitPlot";
import RunModel from "./RunModel";
import BestModel from "./BestModel";
import styles from "../styles/searchPlotData.module.css";
import { useDispatch } from "react-redux";
import { uploadCsvFile } from "../features/theoreticalModeling/theoreticalModelingSlice";

const SearchTMPlotData = () => {
  const dispatch = useDispatch();
  const [inputErrors, setInputErrors] = useState({ ssc: {}, eic: {} });
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      dispatch(uploadCsvFile(formData));
    }

    setSelectedFile(event.target.files[0]);
    event.target.value = null;
  };

  return (
    <div className={styles.searchFormWrapper}>
      <RunModel inputErrors={inputErrors} setInputErrors={setInputErrors} />

      <UploadCSVFile
        selectedFile={selectedFile}
        handleFileChange={handleFileChange}
      />

      <FitPlot
        selectedFile={selectedFile}
        inputErrors={inputErrors}
        setInputErrors={setInputErrors}
      />

      <CornerPlot />

      <BestParameters />

      <BestModel />
    </div>
  );
};

export default SearchTMPlotData;
