import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PuffLoader from "react-spinners/PuffLoader";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
  fetchArticlesAsync,
  setInputValue,
  selectArticles,
} from "./articlesSlice";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import styles from "../../styles/articles.module.css";
import "../../styles/articles.css";

const Articles = ({ animate }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("abstract");
  const [startYear, setStartYear] = useState(1900);
  const [endYear, setEndYear] = useState(new Date().getFullYear());
  const [isExpanded, setIsExpanded] = useState(false);
  const [showSearchInfo, setShowSearchInfo] = useState(true);

  useEffect(() => {
    const header = document.getElementById("header");
    if (isExpanded) {
      header.style.paddingRight = "17px";
    } else {
      header.style.paddingRight = "0";
    }
  }, [isExpanded]);

  const years = Array.from(
    { length: new Date().getFullYear() - 1900 + 1 },
    (_, i) => 1900 + i
  );

  const handleChangeStartYear = (event) => {
    setStartYear(event.target.value);
  };

  const handleChangeEndYear = (event) => {
    setEndYear(event.target.value);
  };

  const { data, error, isLoading, inputValue, showArticles, isSubmitted } =
    useSelector(selectArticles);

  const inputRef = useRef();

  const handleSubmit = () => {
    dispatch(fetchArticlesAsync({ inputValue, value, startYear, endYear }));
    setShowSearchInfo(false);
  };

  const handleChange = (e) => {
    dispatch(setInputValue(e.target.value));
  };

  const handleChangeRadio = (event) => {
    setValue(event.target.value);
  };

  return (
    <section
      id="articles"
      className="pricing bg-light"
      style={{ minHeight: "100vh" }}
    >
      <div className="container">
        <div className="section-title">
          <h2>Article Search</h2>
        </div>

        <div className="row">
          <div data-aos="fade-up" data-aos-delay="300">
            <div className={styles.articlesBody}>
              <div className={styles.articlesOptions}>
                <div className={styles.leftSearch}>
                  <FormLabel
                    component="legend"
                    style={{ width: "fit-content" }}
                  >
                    Search type
                  </FormLabel>
                  <RadioGroup
                    aria-label="abstract"
                    name="abstract"
                    value={value}
                    onChange={handleChangeRadio}
                    sx={{
                      flexDirection: "row",
                      columnGap: "16px",
                    }}
                  >
                    <FormControlLabel
                      value="abstract"
                      control={
                        <Radio sx={{ padding: 0, marginRight: "4px" }} />
                      }
                      label="by name via ADS"
                      sx={{ margin: 0, whiteSpace: "nowrap" }}
                    />
                    <FormControlLabel
                      value="full_text"
                      control={
                        <Radio sx={{ padding: 0, marginRight: "4px" }} />
                      }
                      label="by coordinates via ADS"
                      sx={{ margin: 0, whiteSpace: "nowrap" }}
                    />
                  </RadioGroup>
                </div>

                <div style={{ display: "flex", gap: "20px" }}>
                  <FormControl sx={{ minWidth: 120 }} size="small">
                    <InputLabel id="start-year-label">Start Year</InputLabel>
                    <Select
                      label="Start Year"
                      id="start-year-select"
                      labelId="start-year-label"
                      value={startYear}
                      onChange={handleChangeStartYear}
                      onOpen={() => setIsExpanded(true)}
                      onClose={() => setIsExpanded(false)}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 360,
                          },
                        },
                      }}
                    >
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl sx={{ minWidth: 120 }} size="small">
                    <InputLabel id="end-year-label">End Year</InputLabel>
                    <Select
                      label="End Year"
                      id="end-year-select"
                      labelId="end-year-label"
                      value={endYear}
                      onChange={handleChangeEndYear}
                      onOpen={() => setIsExpanded(true)}
                      onClose={() => setIsExpanded(false)}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 360,
                          },
                        },
                      }}
                    >
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className={styles.articlesSearchWrapper}>
                <TextField
                  value={inputValue}
                  onChange={handleChange}
                  placeholder="Source Name"
                  inputRef={inputRef}
                  sx={{
                    width: "100%", // width of the input
                    maxWidth: "600px",
                    ".MuiOutlinedInput-root": {
                      backgroundColor: "#ffffff", // bg of the input
                    },
                  }}
                />
                <Button
                  sx={{ height: "fit-content", textTransform: "none" }}
                  variant="contained"
                  size="medium"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={!inputValue}
                >
                  Search
                </Button>
              </div>

              <div
                className={showArticles ? styles.articlesWrapperContainer : ""}
              >
                {!isLoading && showArticles && (
                  <div>
                    {data.map(({ title, year, author, link }) => (
                      <div key={link} className={styles.articleWrapper}>
                        <div>
                          <a href={link} target="_blank" rel="noreferrer">
                            {title}
                          </a>
                          <p>{year}</p>
                        </div>
                        <p>
                          {author?.map((item, index) => (
                            <Fragment key={index}>
                              <span>{item}</span>
                              {index !== author.length - 1 && <span>, </span>}
                            </Fragment>
                          ))}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {isLoading && (
                <PuffLoader
                  className={styles.puffLoader}
                  color="#36d7b7"
                  cssOverride={{ margin: "0 auto" }}
                  loading
                  size={100}
                  speedMultiplier={3}
                />
              )}

              {error && <p className={styles.errorText}>{error}</p>}

              {!isLoading && !data.length && error === null && isSubmitted && (
                <p className={styles.noArticles}>No Related Articles</p>
              )}
            </div>

            {showSearchInfo && (
              <div className="articles">
                <div className={styles.searchInfo}>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6 box-wrapper" data-aos="fade-up">
                        <div className="box">
                          <span style={{ fontSize: "24px" }}>
                            Search by Object Name
                          </span>
                          <br />
                          <p>
                            Performs a search using the source name in the full
                            text of the articles. This search is case and
                            space-sensitive and will match the exact source name
                            as it appears in the full text of the articles.
                          </p>
                        </div>
                      </div>

                      <div
                        className="col-lg-6 mt-4 mt-lg-0 box-wrapper"
                        data-aos="fade-up"
                        data-aos-delay="150"
                      >
                        <div className="box">
                          <span style={{ fontSize: "24px" }}>
                            Search by Coordinates
                          </span>
                          <br />
                          <p>
                            Performs a cone search using the coordinates of the
                            object, assuming a radius of two arcseconds. This
                            will retrieve articles about objects within this
                            radius, allowing you to find information on nearby
                            sources as well.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Articles;
