import { useEffect } from "react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import DataAccess from "./features/dataAccess/DataAccess";
import TheoreticalModeling from "./features/theoreticalModeling/TheoreticalModeling";
import Articles from "./features/articles/Articles";
import AboutUs from "./components/AboutUs";
import OurTeam from "./components/OurTeam";
import Contacts from "./components/Contacts";
import Footer from "./components/Footer";
import "./App.css";
import { useDispatch } from "react-redux";
import { fetchDataFromURL } from "./features/theoreticalModeling/theoreticalModelingSlice";
import { scroller } from "react-scroll";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let timeoutId = null;
    const pathSegments = window.location.pathname.split("/");

    if (pathSegments.length === 3 && pathSegments[1] === "batch_result") {
      const uuid = pathSegments[2];

      if (uuid) {
        scroller.scrollTo("theoreticalModeling", {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
        });

        timeoutId = setTimeout(() => {
          dispatch(fetchDataFromURL({ uuid }));
        }, 2000);
      }
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [dispatch]);

  return (
    <div className="App">
      <div>
        <Navbar />
        <Hero />
        <AboutUs />
        <DataAccess />
        <TheoreticalModeling />
        <Articles />
        <OurTeam />
        <Contacts />
        <Footer />
      </div>
    </div>
  );
}

export default App;
