import React, { useState } from "react";
import { sendEmail } from "../api/api";
import "../styles/Contacts.css";

const Contacts = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(null);
  const [isError, setIsError] = useState(null);
  const [isSuccessful, setIsSuccessful] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setIsSuccessful(false);
    setIsError(false);

    try {
      const response = await sendEmail(form);
      console.log(response);

      setIsLoading(false);
      setIsError(false);
      setIsSuccessful(true);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage(error.message);
      setIsSuccessful(false);

      console.error("Error while sending email: ", error);
    }
  };

  return (
    <section
      id="contact"
      className="contact bg-light"
      style={{ minHeight: "100vh" }}
    >
      <div className="container">
        <div className="section-title">
          <h2>Contact</h2>
        </div>

        <div className="row info-box-wrapper" data-aos="fade-up">
          <div className="col-lg-6">
            <div className="info-box mb-4">
              <h3>ICRANet Armenia Center</h3>
              <p style={{ textAlign: "justify" }}>
                ICRANet Armenia is the regional seat of ICRANet in the Republic
                of Armenia. The main scientific activities of the center are in
                the fields of multi-wavelength and multi-messenger astrophysics.
                Specifically, we investigate extreme astronomical events, such
                as the relativistic jets of blazars, which produce multiple
                messengers, including neutrinos, photons, and cosmic rays.
                ICRANet Armenia is a member of the MAGIC Collaboration.
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="info-box mb-4">
              <i className="bx bx-envelope"></i>
              <h3>Email Us</h3>
              <p>mailtommdc(at)gmail.com</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="info-box mb-4">
              <i className="bx bx-map"></i>
              <h3>Our Address</h3>
              <p>Marshal Baghramyan 22A, 0019</p>
              <p>+37410600605</p>
            </div>
          </div>
        </div>

        <div
          className="row justify-content-center contacts-wrapper"
          data-aos="fade-up"
        >
          <div className="col-lg-6">
            <div className="left-wrapper">
              <p>
                The Markarian Multiwavelength Data Center (MMDC) has been
                developed by ICRANet Armenia, supported by the Higher Education
                and Science Committee of Ministry of Education, Science, Culture
                and Sport RA, under the scope of research project No.
                20TTCG-1C015 and 23LCG-1C004. When using data from the MMDC, we
                kindly request the inclusion of the following acknowledgment:
              </p>
              <p>
                "Some of the data used in this work were obtained from the
                MMDC."
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <form className="php-email-form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="form-group mt-3">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mt-3">
                <textarea
                  className="form-control contacts-textarea"
                  name="message"
                  rows="5"
                  placeholder="Message"
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <div className="my-3 send-message-status">
                {isLoading && <div className="loading">Loading</div>}
                {isError && <div className="error-message">{errorMessage}</div>}
                {isSuccessful && (
                  <div className="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                )}
              </div>
              <div className="text-center">
                <button type="submit">Send Message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
