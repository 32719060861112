import React from "react";
import ReactDOM from "react-dom";
import "../styles/logDrawer.css";

function ansiToHtml(logs) {
  logs = logs?.replace(
    // eslint-disable-next-line no-control-regex
    /\x1B\[1;37m/g,
    '<span style="font-weight: bold; color: white;">'
  );

  logs = logs?.replace(
    // eslint-disable-next-line no-control-regex
    /\x1B\[1;32m/g,
    '<span style="font-weight: bold; color: green;">'
  );

  // eslint-disable-next-line no-control-regex
  logs = logs?.replace(/\x1B\[0m/g, "</span>");
  return logs;
}

const LogDrawer = ({ logs, showLogs, closeLogs }) => {
  const portalRoot = document.getElementById("portal-root");

  let styledLogs = ansiToHtml(logs);

  return ReactDOM.createPortal(
    <div className={`logDrawer ${showLogs ? "open" : ""}`}>
      <div className="container">
        <div className="wrapper-div">
          <button className="close-btn" onClick={closeLogs}>
            X
          </button>
          <div
            className="logs"
            dangerouslySetInnerHTML={{ __html: styledLogs }}
          ></div>
        </div>
      </div>
    </div>,
    portalRoot
  );
};

export default LogDrawer;
