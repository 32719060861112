import { useSelector } from "react-redux";
import { selectError } from "../features/theoreticalModeling/theoreticalModelingSlice";
import TheoreticalModelingPlotComponent from "./TheoreticalModelingPlotComponent";
import styles from "../styles/sedPlot.module.css";

const TheoreticalModelingPlot = () => {
  const error = useSelector(selectError);

  return (
    <div className={styles.wrapper}>
      <TheoreticalModelingPlotComponent />
      {error && <p className={styles.errorText}>{error}</p>}
    </div>
  );
};

export default TheoreticalModelingPlot;
