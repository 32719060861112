// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sedLcAnimationWrapper_sedLcAnimationVideo__X44zX {\n  width: 80%;\n  max-width: 1000px;\n  margin: 0 auto;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/sedLcAnimationWrapper.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".sedLcAnimationVideo {\n  width: 80%;\n  max-width: 1000px;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sedLcAnimationVideo": "sedLcAnimationWrapper_sedLcAnimationVideo__X44zX"
};
export default ___CSS_LOADER_EXPORT___;
