import { Link } from "react-scroll";
import "../styles/Hero.css";

const Hero = () => {
  return (
    <section name="hero" id="hero" className="scrolled-offset">
      <div className="container position-relative fadeInUp">
        <h1>Welcome to MMDC</h1>
        <h2 className="hero-description">
          Markarian Multiwavelength Data Center (MMDC): a platform for building
          and analyzing multiwavelength SEDs.
        </h2>
        <Link
          className="btn-get-started scrollto"
          to="about"
          spy={true}
          smooth={true}
          offset={-70}
          duration={0}
        >
          GET STARTED
        </Link>
      </div>
    </section>
  );
};

export default Hero;
