import React from "react";
import { Button } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFilterDates,
  setFilterDates,
} from "../features/dataAccess/dataAccessSlice";
import dayjs from "dayjs";
import { dateToMJD } from "../helpers/functions";
import styles from "../styles/filterDates.module.css";

const FilterByDate = ({ setLastButtonClicked }) => {
  const dispatch = useDispatch();
  const filterDates = useSelector(selectFilterDates);

  const handleDatesChange = (name, newValue) => {
    dispatch(
      setFilterDates({
        ...filterDates,
        [name]: newValue ? newValue.format() : null,
      })
    );
  };

  const handleClick = () => {
    let startMJD = null;
    let endMJD = null;

    if (dayjs(filterDates.start).isValid()) {
      startMJD = dateToMJD(dayjs(filterDates.start).toDate());
    }

    if (dayjs(filterDates.end).isValid()) {
      endMJD = dateToMJD(dayjs(filterDates.end).toDate());
    }

    dispatch(
      setFilterDates({
        ...filterDates,
        startInNum: Math.floor(startMJD) || null,
        endInNum: Math.floor(endMJD) || null,
      })
    );
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={styles.datesWrapper}>
          <DatePicker
            name="start"
            value={filterDates.start ? dayjs(filterDates.start) : null}
            onChange={(newValue) => {
              handleDatesChange("start", newValue);
            }}
          />
          <DatePicker
            name="end"
            value={filterDates.end ? dayjs(filterDates.end) : null}
            onChange={(newValue) => {
              handleDatesChange("end", newValue);
            }}
          />

          <Button
            type="submit"
            variant="contained"
            className={styles.filterBtn}
            onClick={() => {
              setLastButtonClicked("FilterByDate");
              handleClick();
            }}
          >
            Filter
          </Button>
        </div>
      </LocalizationProvider>
    </div>
  );
};

export default FilterByDate;
