import React, { useRef, useState } from "react";
import { Link } from "react-scroll";
import { ReactComponent as FacebookIcon } from "bootstrap-icons/icons/facebook.svg";
import { subscribe } from "../api/api";
import { emailRegex } from "../constants/constants.js";
import { ChevronRight } from "../assets/images/svg.js";
import "../styles/Footer.css";

const Footer = () => {
  const emailWrapperRef = useRef();
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState(false);
  const [successfullySubscribed, setSuccessfullySubscribed] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value && !emailRegex.test(value)) {
      emailWrapperRef.current.style.borderColor = "#cc1616";
    } else {
      emailWrapperRef.current.style.borderColor = "transparent";
    }

    setInputValue(value);
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    setError(false);
    setSuccessfullySubscribed(false);

    if (!emailRegex.test(inputValue)) {
      return;
    }

    try {
      const response = await subscribe(inputValue);

      if (response.detail === "Successfully subscribed!") {
        setError(false);
        setSuccessfullySubscribed(true);
      }
    } catch (error) {
      setError(true);
      setSuccessfullySubscribed(false);
      console.error("Error while subscribing: ", error);
    }
  };

  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-info">
                <h3>MMDC</h3>
                <p style={{ textAlign: "justify" }}>
                  The data center offers a platform for generating
                  multiwavelength SEDs of blazars. With interactive plotting
                  options and time filters, we empower researchers to dissect
                  and understand complex time resolved SEDs with ease.
                </p>
                <div className="social-links mt-3 d-flex justify-content-center gap-1">
                  <a
                    href="https://www.facebook.com/icranet.armenia/"
                    className="facebook"
                  >
                    <FacebookIcon />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-6 footer-links">
              <h4>Quick Links</h4>
              <ul>
                <li>
                  <ChevronRight />
                  <Link
                    className="scrollto"
                    to="hero"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={0}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <ChevronRight />
                  <Link
                    className="scrollto"
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={0}
                  >
                    About
                  </Link>
                </li>
                <li>
                  <ChevronRight />
                  <Link
                    className="scrollto"
                    to="services"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={0}
                  >
                    Data access
                  </Link>
                </li>
                <li>
                  <ChevronRight />
                  <Link
                    className="scrollto"
                    to="theoreticalModeling"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={0}
                  >
                    Theoretical Modeling
                  </Link>
                </li>
                <li>
                  <ChevronRight />
                  <Link
                    className="scrollto"
                    to="articles"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={0}
                  >
                    Articles
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-2 col-md-6 footer-links">
              <h4>Other Initiatives</h4>
              <ul>
                <li>
                  <ChevronRight />
                  <a
                    href="http://firmamento.hosting.nyu.edu/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Firmamento
                  </a>
                </li>
                <li>
                  <ChevronRight />
                  <a
                    href="https://openuniverse.asi.it"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Open Universe
                  </a>
                </li>
                <li>
                  <ChevronRight />
                  <a
                    href="https://tools.ssdc.asi.it/SED/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    SSDC
                  </a>
                </li>
                <li>
                  <ChevronRight />
                  <a
                    href="https://tools.ssdc.asi.it/SED/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Radio Galaxy Zoo
                  </a>
                </li>
                <li>
                  <ChevronRight />
                  <a
                    href="http://ned.ipac.caltech.edu"
                    target="_blank"
                    rel="noreferrer"
                  >
                    NASA/IPAC
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-4 col-md-6 footer-newsletter">
              <h4>Newsletter</h4>
              <h6 style={{ fontSize: "14px" }}>Signup for the updates</h6>
              <form action="" method="post" onSubmit={handleSubmitForm}>
                <div ref={emailWrapperRef} className="email-wrapper">
                  <input
                    type="email"
                    name="email"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                </div>
                <input type="submit" value="Subscribe" />
              </form>

              {error && (
                <p style={{ marginTop: "8px", color: "#cc1616" }}>
                  This email is already subscribed!
                </p>
              )}

              {successfullySubscribed && (
                <p style={{ marginTop: "8px", color: "#00b918" }}>
                  Successfully subscribed!
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          &copy; 2023 ICRANet Armenia. All Rights Reserved
        </div>
      </div>
    </footer>
  );
};

export default Footer;
