import React from "react";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { selectBestModelLink } from "../features/theoreticalModeling/theoreticalModelingSlice";
import styles from "../styles/searchPlotData.module.css";

const BestModel = () => {
  const bestModelLink = useSelector(selectBestModelLink);

  return (
    <div className={styles.bestModelWrapper}>
      <Button
        component="a"
        href={bestModelLink}
        download
        variant="contained"
        color="primary"
        size="small"
        fullWidth
        disabled={!bestModelLink}
        className={styles.bestModel}
      >
        Best Model
      </Button>
    </div>
  );
};

export default BestModel;
