export function convertDegreesToRaDec(raDecimal, decDecimal) {
  const raHours = raDecimal / 15; // Convert degrees to hours

  let raHour = Math.floor(raHours);
  let raMin = Math.floor((raHours - raHour) * 60);
  let raSec = Math.round(((raHours - raHour) * 60 - raMin) * 60 * 10) / 10;

  let decDeg = Math.floor(decDecimal);
  let decMin = Math.floor((decDecimal - decDeg) * 60);
  let decSec = Math.round(((decDecimal - decDeg) * 60 - decMin) * 60 * 10) / 10;

  return {
    raHour,
    raMin,
    raSec,
    decDeg,
    decMin,
    decSec,
  };
}

export const transformData = (data) => {
  const orderList = [
    "radio_waves",
    "microwaves",
    "infrared",
    "visible",
    "ultraviolet",
    "x_rays",
    "gamma_rays",
  ];

  let groupedLegends = {};

  orderList.forEach((outerKey) => {
    if (data.hasOwnProperty(outerKey)) {
      groupedLegends[outerKey] = {
        show: true,
        subCategories: Object.fromEntries(
          Object.keys(data[outerKey]).map((innerKey) => {
            return [
              innerKey,
              {
                show: true,
                color: data[outerKey][innerKey].Other?.color,
              },
            ];
          })
        ),
      };
    }
  });

  return groupedLegends;
};

export const isEmptyObject = (obj) => {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }
  return true;
};

export const triggerCSVDownload = (csvData, filename = "data.csv") => {
  const blob = new Blob([csvData], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const dateToMJD = (date) => {
  const JD = date.valueOf() / 86400000 + 2440587.5;
  const MJD = JD - 2400000.5;
  return MJD;
};

export const scrollToElement = (elementId, yOffset = 0) => {
  return new Promise((resolve) => {
    const element = document.getElementById(elementId);
    if (element) {
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
      setTimeout(resolve, 500);
    } else {
      resolve();
    }
  });
};
