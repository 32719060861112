import React, { useState } from "react";
import { Box, Tab, Tabs, Tooltip } from "@mui/material";
import PlotComponent from "./PlotComponent";
import AladinComponent from "./AladinComponent";
import RelatedArticles from "./RelatedArticles";
import styles from "../styles/dataSection.module.css";
import SedLcAnimation from "./SedLcAnimation";

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const TabPanel = React.forwardRef((props, ref) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      ref={ref}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
});

const DataSection = () => {
  const [value, setValue] = useState(0);

  const tabsStyles = {
    "& .MuiTabs-indicator": {
      display: "none",
    },
  };

  const tabStyles = {
    "&.Mui-selected": {
      backgroundColor: "#cc1616",
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: "#cc1616",
      color: "#fff",
    },
    marginRight: "1px",
    borderRadius: "4px",
  };

  return (
    <Box className={styles.dataSectionWrapper}>
      <Tabs
        value={value}
        onChange={(e, value) => setValue(value)}
        aria-label="Plot info tabs"
        sx={tabsStyles}
      >
        <Tab
          label="SED"
          {...a11yProps(0)}
          className={styles.tab}
          sx={tabStyles}
        />
        <Tab
          label="SED/LC Animation"
          {...a11yProps(1)}
          className={styles.tab}
          sx={tabStyles}
        />
        <Tab
          label="Aladin"
          {...a11yProps(2)}
          className={styles.tab}
          sx={tabStyles}
        />
        <Tab
          label="Related Articles"
          {...a11yProps(3)}
          className={styles.tab}
          sx={tabStyles}
        />
      </Tabs>
      <TabPanel className={styles.plotTabPanel} value={value} index={0}>
        <PlotComponent />
      </TabPanel>
      <Tooltip>
        <TabPanel value={value} index={1}>
          <SedLcAnimation />
        </TabPanel>
      </Tooltip>
      <TabPanel value={value} index={2}>
        <AladinComponent />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <RelatedArticles />
      </TabPanel>
    </Box>
  );
};

export default DataSection;
