import { useSelector } from "react-redux";
import { convertDegreesToRaDec } from "../helpers/functions";
import { selectSourceInfo } from "../features/dataAccess/dataAccessSlice";
import styles from "../styles/sourceInfo.module.css";

const SourceInfo = () => {
  const { ra, dec, W_peak, gal_lat, gal_long, redshift } =
    useSelector(selectSourceInfo);

  const { raHour, raMin, raSec, decDeg, decMin, decSec } =
    convertDegreesToRaDec(ra, dec);

  const newRedShift = redshift ? redshift.replace(/\+-/g, "±") : redshift;

  const [left, right] =
    newRedShift?.split("±").map((val) => {
      const trimmedVal = val.trim();

      return trimmedVal.includes(".")
        ? parseFloat(trimmedVal).toFixed(3)
        : trimmedVal;
    }) || [];

  const formattedRedShift = left && right ? `${left} ± ${right}` : "";

  const formatValue = (value) => {
    if (value === undefined || value === null || Number.isNaN(value)) {
      return "";
    }
    return value;
  };

  return (
    <div className={styles.infoWrapper}>
      <div className={styles.sourceInfoWrapper}>
        <div className={styles.sectionWrapper}>
          <p>RA/Dec Equatorial (J2000.0)</p>
          <div>
            <p>
              Ra: {formatValue(ra)}{" "}
              {ra !== undefined &&
                `(${formatValue(raHour)} ${formatValue(raMin)} ${formatValue(
                  raSec
                )})`}
            </p>
            <p>
              Dec: {formatValue(dec)}{" "}
              {dec !== undefined &&
                `(${formatValue(decDeg)} ${formatValue(decMin)} ${formatValue(
                  decSec
                )})`}
            </p>
          </div>
        </div>
        <div className={`${styles.sectionWrapper} ${styles.hideOnMobile}`}>
          <p>Galactic Coordinates</p>
          <div>
            <p>Gal long: {gal_long}</p>
            <p>Gal lat: {gal_lat}</p>
          </div>
        </div>
        <div className={styles.sectionWrapper}>
          <p>Redshift</p>
          <p>{formattedRedShift}</p>
        </div>
        <div className={styles.sectionWrapper}>
          <p>W-peak</p>
          <p>{W_peak}</p>
        </div>
      </div>
    </div>
  );
};

export default SourceInfo;
