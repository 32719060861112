import { configureStore } from "@reduxjs/toolkit";
import dataAccessReducer from "../features/dataAccess/dataAccessSlice";
import articlesReducer from "../features/articles/articlesSlice";
import theoreticalModelingReducer from "../features/theoreticalModeling/theoreticalModelingSlice";

export const store = configureStore({
  reducer: {
    articles: articlesReducer,
    dataAccess: dataAccessReducer,
    theoreticalModeling: theoreticalModelingReducer,
  },
});
