import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import Plot from "react-plotly.js";
import PuffLoader from "react-spinners/PuffLoader";
import { Typography } from "@mui/material";
import {
  selectAxisViews,
  selectBestCoordinates,
  selectCoordinates,
  selectIncomingCoordinates,
  selectMinMaxCoordinates,
  selectPlotDataErrorText,
  selectShowPlotLoader,
  selectUploadDataCoordinates,
  selectUploadedFile,
} from "../features/theoreticalModeling/theoreticalModelingSlice";
import SearchTMPlotData from "./SearchTMPlotData";
import styles from "../styles/theoreticalModelingPlotComponent.module.css";
import TMSourceInfo from "./TMSourceInfo";

const PlotComponent = () => {
  const [minMax, setMinMax] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const showPlotLoader = useSelector(selectShowPlotLoader);
  const plotDataErrorText = useSelector(selectPlotDataErrorText);
  const { nu, nuFnu } = useSelector(selectCoordinates);
  const axisViews = useSelector(selectAxisViews);
  const minMaxCoordinates = useSelector(selectMinMaxCoordinates);
  const uploadDataCoordinates = useSelector(selectUploadDataCoordinates);
  const incomingCoordinates = useSelector(selectIncomingCoordinates);
  const bestCoordinates = useSelector(selectBestCoordinates);
  const uploadedFile = useSelector(selectUploadedFile);

  const [state, setState] = useState({
    data: [],
    mode: "lines",
    layout: {
      autosize: true,
      shapes: [
        {
          type: "rect",
          xref: "paper",
          yref: "paper",
          x0: 0,
          y0: 0,
          x1: 1,
          y1: 1,
          line: {
            color: "black",
            width: 1,
          },
        },
      ],
      legend: {
        title: {
          text: "Catalog",
        },
        itemsizing: "constant",
        tracegroupgap: 10,
      },
      xaxis: {
        type: "log",
        autorange: false,
        exponentformat: "power",
        ticks: "inside",
        tickcolor: "black",
        tickfont: {
          size: 16,
        },
        tickmode: "linear",
        dtick: 1,
        title: {
          text: `𝜈${axisViews.xAxis.label[0]}`,
          font: {
            size: 18,
          },
        },
      },
      yaxis: {
        type: "log",
        autorange: false,
        exponentformat: "power",
        ticks: "inside",
        tickcolor: "black",
        tickfont: {
          size: 16,
        },
        tickmode: "linear",
        dtick: 1,
        title: {
          text: `${axisViews.yAxis.label[0]}`,
          font: {
            size: 18,
          },
        },
      },
    },
    frames: [],
    config: {},
  });

  const updatedDataAndAnnotations = useMemo(() => {
    let updatedData = [];

    // Existing datasets
    updatedData.push({
      x: nu,
      y: nuFnu,
      type: "scattergl",
      mode: "lines",
      line: { color: "red", size: 10 },
      showlegend: false,
      hoverinfo: "none",
    });

    // Adding incomingCoordinates to the plot
    incomingCoordinates.forEach((coordinate) => {
      updatedData.push({
        x: coordinate.nu,
        y: coordinate.nuFnu,
        type: "scattergl",
        mode: "lines",
        line: { color: "lightgrey", size: 10 },
        showlegend: false,
        hoverinfo: "none",
      });
    });

    // Adding bestCoordinates to the plot
    if (bestCoordinates.nu && bestCoordinates.nuFnu) {
      updatedData.push({
        x: bestCoordinates.nu,
        y: bestCoordinates.nuFnu,
        type: "scattergl",
        mode: "lines",
        line: { color: "red", size: 10 },
        showlegend: false,
        hoverinfo: "none",
      });
    }

    updatedData.push({
      x: uploadDataCoordinates.x,
      y: uploadDataCoordinates.y,
      type: "scattergl",
      showlegend: false,
      mode: "markers",
      marker: { color: "#1f8dff", size: 7 },
      error_y: {
        type: "data",
        array: uploadDataCoordinates.dy,
        width: 0,
      },
    });

    return { updatedData };
  }, [nu, nuFnu, uploadDataCoordinates, incomingCoordinates, bestCoordinates]);

  const cssOverride = useMemo(
    () => ({
      margin: "0 auto",
      top: "35%",
    }),
    []
  );

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 992);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const minX = minMaxCoordinates?.nu[0]
      ? Math.log10(minMaxCoordinates.nu[0])
      : 7;
    const maxX = minMaxCoordinates?.nu[1]
      ? Math.log10(minMaxCoordinates.nu[1])
      : 31;
    const minY = minMaxCoordinates?.nuFnu[0]
      ? Math.log10(minMaxCoordinates.nuFnu[0])
      : -14;
    const maxY = minMaxCoordinates?.nuFnu[1]
      ? Math.log10(minMaxCoordinates.nuFnu[1])
      : -8;

    setMinMax({ minX, minY, maxX, maxY });
  }, [minMaxCoordinates]);

  useEffect(() => {
    const { minX, minY, maxX, maxY } = minMax;
    const { updatedData } = updatedDataAndAnnotations;

    const tickValsX = [];
    const tickValsY = [];

    const xAxisLabel = axisViews.xAxis.label[0];
    const yAxisLabel = axisViews.yAxis.label[0];

    for (let i = Math.ceil(minX); i <= Math.floor(maxX); i++) {
      tickValsX.push(i);
    }
    for (let i = Math.ceil(minY); i <= Math.floor(maxY); i++) {
      tickValsY.push(i);
    }

    const tickTextX = tickValsX.map((v) => `10^${v}`);

    const tickTextY = tickValsY.map((v) => `10^${v}`);

    if (uploadedFile && uploadedFile.frequency.length > 0) {
      updatedData.push({
        x: uploadedFile.frequency,
        y: uploadedFile.flux,
        type: "scattergl",
        showlegend: false,
        mode: "markers",
        marker: { color: "#1f8dff", size: 7 },
        error_y: {
          type: "data",
          array: uploadedFile.flux_err,
          width: 0,
        },
      });
    }

    setState((prevState) => ({
      ...prevState,
      data: updatedData,
      layout: {
        ...prevState.layout,
        margin: {
          b: isMobile ? 40 : 160,
          t: 50,
          r: isMobile ? 0 : 250,
        },
        xaxis: {
          ...prevState.layout.xaxis,
          domain: [0, 0.975],
          title: {
            ...prevState.layout.xaxis.title,
            text: `${
              xAxisLabel !== "eV"
                ? "𝜈<sub>obs</sub>[" + xAxisLabel + "]"
                : "E<sub>obs</sub>[" + xAxisLabel + "]"
            }`,
            standoff: 10,
          },
          range: [minX, maxX],
          tickmode: "auto",
          tickvals: tickValsX,
          ticktext: tickTextX,
        },
        yaxis: {
          ...prevState.layout.yaxis,
          domain: [0, 0.955],
          title: {
            ...prevState.layout.yaxis.title,
            text: `${yAxisLabel}`,
          },
          range: [minY, maxY],
          tickmode: "linear",
          tickvals: tickValsY,
          ticktext: tickTextY,
        },
      },
    }));
  }, [axisViews, minMax, isMobile, updatedDataAndAnnotations, uploadedFile]);

  return (
    <div>
      <div
        className={`${styles.plotComponentWrapper} ${styles.toggleBackgroundColor}`}
      >
        <div className={styles.plotlyPlotWrapper}>
          <Plot
            data={state.data}
            layout={state.layout}
            config={{ doubleClick: "autosize" }}
            useResizeHandler
            className={styles.plotlyPlot}
          />
          {showPlotLoader && (
            <PuffLoader
              className={styles.puffLoader}
              color="#36d7b7"
              cssOverride={cssOverride}
              loading
              size={100}
              speedMultiplier={3}
            />
          )}
          {plotDataErrorText && (
            <Typography className={styles.plotDataErrorText}>
              {plotDataErrorText}
            </Typography>
          )}
        </div>

        {!isMobile && <SearchTMPlotData />}
      </div>

      <div className={styles.mobileFilterInfoWrapper}>
        {isMobile && <SearchTMPlotData />}

        <TMSourceInfo />
      </div>
    </div>
  );
};

export default PlotComponent;
