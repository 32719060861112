import { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { emailRegex } from "../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTheoreticalModelingSendEmail,
  selectEmailInput,
  selectFitPlotErrorText,
  selectSearchParams,
  selectSuccessfulSentEmail,
  setEmailInput,
  setSuccessfulSentEmail,
} from "../features/theoreticalModeling/theoreticalModelingSlice";
import styles from "../styles/searchPlotData.module.css";

const FitPlot = ({ selectedFile, inputErrors, setInputErrors }) => {
  const dispatch = useDispatch();
  const [emailInputError, setEmailInputError] = useState("");
  const [fitError, setFitError] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState(null);
  const emailInput = useSelector(selectEmailInput);
  const successfulSentEmail = useSelector(selectSuccessfulSentEmail);
  const { checkboxData, sscInputsData, eicInputsData } =
    useSelector(selectSearchParams);
  const fitPlotErrorText = useSelector(selectFitPlotErrorText);

  useEffect(() => {
    if (fitPlotErrorText && fitPlotErrorText?.length > 0) {
      setOpenModal(true);
    }
  }, [fitPlotErrorText]);

  useEffect(() => {
    if (successfulSentEmail !== null) {
      if (successfulSentEmail) {
        setStatus("Success");
        dispatch(setSuccessfulSentEmail(null));
      } else {
        setStatus("Error");
      }

      setOpenModal(true);
    }
  }, [dispatch, successfulSentEmail]);

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleEmailInputSubmit = () => {
    let input = null;
    let newInputErrors = {};

    if (checkboxData.value === "SSC") {
      Object.keys(inputErrors.ssc).forEach((key) => {
        newInputErrors[key] = "";
      });

      input = sscInputsData.find((obj) => obj.name === "z");

      setInputErrors({
        ...inputErrors,
        ssc: {
          ...newInputErrors,
          z: !input.value
            ? "Required field"
            : input.value <= 0 || input.value > 10
            ? "> 0 up to 10"
            : "",
        },
      });
    } else if (checkboxData.value === "EIC") {
      Object.keys(inputErrors.eic).forEach((key) => {
        newInputErrors[key] = "";
      });

      input = eicInputsData.find((obj) => obj.name === "z");

      setInputErrors({
        ...inputErrors,
        eic: {
          ...newInputErrors,
          z: !input.value
            ? "Required field"
            : input.value <= 0 || input.value > 10
            ? "> 0 up to 10"
            : "",
        },
      });
    }

    if (!selectedFile) {
      setFitError("Please upload a file first.");
    }

    const email = emailInput.value;

    if (!email) {
      setEmailInputError(`Required field`);
    } else if (!emailRegex.test(email)) {
      setEmailInputError(`Write a valid email`);
    } else {
      setEmailInputError("");
    }

    if (
      email &&
      emailRegex.test(email) &&
      selectedFile &&
      input.value &&
      input.value > 0 &&
      input.value <= 10
    ) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      dispatch(fetchTheoreticalModelingSendEmail(formData));
    }
  };

  const handleEmailInputChange = (e, name) => {
    const value = e.target.value;

    dispatch(setEmailInput({ name, value }));
  };

  return (
    <div className={styles.fitPlotWrapper}>
      <div className={styles.fitTextField}>
        <TextField
          label="email"
          variant="outlined"
          fullWidth
          size="small"
          value={emailInput.value}
          onChange={(e) => handleEmailInputChange(e, "email")}
          error={!!emailInputError}
        />
        <p className={styles.errorText}>
          {!!emailInputError ? emailInputError : ""}
        </p>
      </div>

      <div>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          size="small"
          onClick={handleEmailInputSubmit}
        >
          Fit
        </Button>
        <p className={styles.errorText}>{!selectedFile ? fitError : ""}</p>
      </div>

      <Dialog
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            color: status === "Success" ? "green" : "#cc1616",
            fontWeight: "bold",
          }}
        >
          {status}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ textAlign: "justify" }}
          >
            {status === "Success" ? (
              <span>
                Your file has been received. We are now processing your data.
                <br />
                You will receive the fitting results via email once they are
                ready.
              </span>
            ) : status === "Error" ? (
              fitPlotErrorText
            ) : (
              ""
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FitPlot;
