import React from "react";
import { Button } from "@mui/material";
import styles from "../styles/searchPlotData.module.css";
import { useSelector } from "react-redux";
import { selectPdfUrl } from "../features/theoreticalModeling/theoreticalModelingSlice";

const CornerPlot = () => {
  const pdfUrl = useSelector(selectPdfUrl);

  return (
    <div className={styles.cornerPlotWrapper}>
      <Button
        component="a"
        download
        variant="contained"
        size="small"
        fullWidth
        href={pdfUrl}
        disabled={!pdfUrl}
        className={styles.cornerPlot}
      >
        Corner Plot
      </Button>
    </div>
  );
};

export default CornerPlot;
